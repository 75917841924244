var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.i18n && _vm.i18n.navigationMenu)?_c('div',{staticClass:"my-sidenav"},[_c('dl',{staticClass:"my-sidenav__list"},[(_vm.meterId && _vm.$route.name !== 'MyMeterDashboard')?_c('router-link',{staticClass:"my-sidenav__list__back",attrs:{"to":{ name: 'MyMeterDashboard', params: { id: _vm.meterId } }}},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"size":"0 0 24 24","symbol":"arrows-small-left"}}),_vm._v(" "+_vm._s(_vm.i18n.backMeters)+" ")]):_vm._e(),(_vm.$route.name === 'MyMeterDashboard')?_c('router-link',{staticClass:"my-sidenav__list__back",attrs:{"to":{ name: 'MyDashboard' }}},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"size":"0 0 24 24","symbol":"arrows-small-left"}}),_vm._v(" "+_vm._s(_vm.i18n.navigationMenu.mainDashboard)+" ")]):_vm._e(),(!_vm.meterId)?[_c('dt',{staticClass:"my-sidenav__term"},[_vm._v(_vm._s(_vm.i18n.navigationMenu.mainTitle))]),_c('dd',{staticClass:"my-sidenav__item"},[_c('router-link',{attrs:{"to":{ name: 'MyDashboard' }}},[_vm._v(" "+_vm._s(_vm.i18n.navigationMenu.mainDashboard)+" ")])],1)]:_vm._e(),(_vm.meterId)?[_c('dt',{staticClass:"my-sidenav__term"},[_vm._v(" "+_vm._s(_vm.i18n.meterInfo.mymeter.title)+" ")]),(_vm.hasData.index)?_c('dd',{staticClass:"my-sidenav__item"},[_c('router-link',{attrs:{"to":{ name: 'MyMeterHistory', params: { id: _vm.meterId } }}},[_vm._v(" "+_vm._s(_vm.i18n.meterInfo.index.title)+" ")])],1):_vm._e(),(_vm.hasData.invoice)?_c('dd',{staticClass:"my-sidenav__item"},[_c('router-link',{attrs:{"to":{ name: 'MyMeterInvoices', params: { id: _vm.meterId } }}},[_vm._v(" "+_vm._s(_vm.i18n.meterInvoices.title)+" ")])],1):_vm._e(),_c('dd',{staticClass:"my-sidenav__item"},[(_vm.hasData.meter)?_c('router-link',{attrs:{"to":{
            name: 'MyMeterInfo',
            params: {
              id: _vm.meterId,
            },
          }}},[_vm._v(" "+_vm._s(_vm.i18n.meterDetails)+" ")]):_vm._e()],1)]:[_c('dt',{staticClass:"my-sidenav__term"},[_vm._v(" "+_vm._s(_vm.i18n.navigationMenu.profile.title)+" ")]),_c('dd',{staticClass:"my-sidenav__item"},[_c('router-link',{attrs:{"to":{ name: 'MyProfile', params: { sub: 'view' } }}},[_vm._v(" "+_vm._s(_vm.i18n.navigationMenu.profile.datas)+" ")])],1),_c('dd',{staticClass:"my-sidenav__item"},[_c('router-link',{attrs:{"to":{ name: 'MyProfile', params: { sub: 'preferences' } }}},[_vm._v(" "+_vm._s(_vm.i18n.navigationMenu.profile.contact)+" ")])],1),_c('dd',{staticClass:"my-sidenav__item"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openPopup('https://login.resa.be/adfs/portal/updatepassword/')}}},[_vm._v(" "+_vm._s(_vm.i18n.navigationMenu.profile.password)+" ")]),(
            _vm.i18n.navigationMenu.profile.links &&
            _vm.i18n.navigationMenu.profile.links.length > 0
          )?_c('div',{staticClass:"mt-xxs"},_vm._l((_vm.i18n.navigationMenu.profile.links),function(item,index){return _c('dd',{key:index,staticClass:"my-sidenav__item"},[_c('a',{attrs:{"href":item.link.url,"target":item.link.target,"rel":"noopener"}},[_vm._v(" "+_vm._s(item.link.title)+" ")])])}),0):_vm._e()]),(_vm.user && _vm.user.dossiers && _vm.user.dossiers.length > 0)?[_c('dt',{staticClass:"my-sidenav__term"},[_vm._v(" "+_vm._s(_vm.i18n.navigationMenu.works.title)+" ")]),(_vm.user && _vm.user.dossiers.length > 0)?_c('dd',{staticClass:"my-sidenav__item"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.clickMyRecord($event)}}},[_vm._v(" "+_vm._s(_vm.i18n.navigationMenu.works.suivi)+" ")])]):_vm._e(),(
            _vm.i18n.navigationMenu.works.links &&
            _vm.i18n.navigationMenu.works.links.length > 0
          )?_c('div',{staticClass:"mt-xxs"},_vm._l((_vm.i18n.navigationMenu.works.links),function(item,index){return _c('dd',{key:index,staticClass:"my-sidenav__item"},[_c('a',{attrs:{"href":item.link.url,"target":item.link.target,"rel":"noopener"}},[_vm._v(" "+_vm._s(item.link.title)+" ")])])}),0):_vm._e()]:_vm._e(),(_vm.isPpp)?[_c('dt',{staticClass:"my-sidenav__term"},[_vm._v(" "+_vm._s(_vm.i18n.navigationMenu.prepayment.title)+" ")]),_c('dd',{staticClass:"my-sidenav__item"},[_c('a',{attrs:{"href":_vm.VUE_APP_ATRIAS_DOMAIN,"rel":"noopener","target":"_blank"},on:{"click":function($event){return _vm.clickUrlPPP()}}},[_vm._v(" "+_vm._s(_vm.i18n.sidebar.pppFirst)+" ")])]),_c('dd',{staticClass:"my-sidenav__item"},[_c('a',{attrs:{"href":_vm.VUE_APP_ATRIAS_DOMAIN,"rel":"noopener","target":"_blank"},on:{"click":function($event){return _vm.clickUrlPPP()}}},[_vm._v(" "+_vm._s(_vm.i18n.sidebar.pppSecond)+" ")])]),_c('dd',{staticClass:"my-sidenav__item"},[_c('a',{attrs:{"href":_vm.VUE_APP_DOMAIN +
              "/" + _vm.currentLang + "/compteur-communicant-prepaiement/","rel":"noopener","target":"_blank"},on:{"click":function($event){return _vm.clickUrlPPP()}}},[_vm._v(" "+_vm._s(_vm.i18n.sidebar.pppBrochure)+" ")])]),(
            _vm.i18n.navigationMenu.prepayment.links &&
            _vm.i18n.navigationMenu.prepayment.links.length > 0
          )?_c('div',{staticClass:"mt-xxs"},_vm._l((_vm.i18n.navigationMenu.prepayment.links),function(item,index){return _c('dd',{key:index,staticClass:"my-sidenav__item"},[_c('a',{attrs:{"href":item.link.url,"target":item.link.target,"rel":"noopener"},on:{"click":function($event){return _vm.clickUrlPPP()}}},[_vm._v(" "+_vm._s(item.link.title)+" ")])])}),0):_vm._e()]:[_c('dt',{staticClass:"my-sidenav__term"},[_vm._v(" "+_vm._s(_vm.i18n.navigationMenu.prepayment.title)+" ")]),_c('dd',{staticClass:"my-sidenav__item"},[_c('router-link',{attrs:{"to":{ name: 'MyPpp' }}},[_vm._v(" "+_vm._s(_vm.i18n.navigationMenu.prepayment.activate)+" ")])],1),(
            _vm.i18n.navigationMenu.prepayment.links &&
            _vm.i18n.navigationMenu.prepayment.links.length > 0
          )?_c('div',{staticClass:"mt-xxs"},_vm._l((_vm.i18n.navigationMenu.prepayment.links),function(item,index){return _c('dd',{key:index,staticClass:"my-sidenav__item"},[_c('a',{attrs:{"href":item.link.url,"target":item.link.target,"rel":"noopener"}},[_vm._v(" "+_vm._s(item.link.title)+" ")])])}),0):_vm._e()],(
          _vm.i18n.navigationMenu.mobElec && _vm.i18n.navigationMenu.mobElec.title
        )?[_c('dt',{staticClass:"my-sidenav__term"},[_vm._v(" "+_vm._s(_vm.i18n.navigationMenu.mobElec.title)+" ")]),(_vm.i18n.navigationMenu.mobElec.bornes)?_c('dd',{staticClass:"my-sidenav__item"},[_c('router-link',{attrs:{"to":{ name: 'chargingTerminals' }}},[_vm._v(" "+_vm._s(_vm.i18n.navigationMenu.mobElec.bornes)+" ")])],1):_vm._e(),(_vm.i18n.navigationMenu.mobElec.simulation)?_c('dd',{staticClass:"my-sidenav__item"},[_c('CustomLink',{attrs:{"type":"router","content":{
              label: _vm.i18n.navigationMenu.mobElec.simulation,
              to: { name: 'MobilitySimulations' },
            }}})],1):_vm._e(),(
            _vm.i18n.navigationMenu.mobElec.links &&
            _vm.i18n.navigationMenu.mobElec.links.length > 0
          )?_c('div',{staticClass:"mt-xxs"},_vm._l((_vm.i18n.navigationMenu.mobElec.links),function(item,index){return _c('dd',{key:index,staticClass:"my-sidenav__item"},[_c('a',{attrs:{"href":item.link.url,"target":item.link.target,"rel":"noopener"}},[_vm._v(" "+_vm._s(item.link.title)+" ")])])}),0):_vm._e()]:_vm._e()],(_vm.user.smartPortal)?[_c('dt',{staticClass:"my-sidenav__term"},[_vm._v(_vm._s(_vm.i18n.navigationMenu.conso.title))]),_c('dd',{staticClass:"my-sidenav__item"},[_c('a',{attrs:{"href":_vm.VUE_APP_MACONSO_DOMAIN,"rel":"noopener","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.i18n.navigationMenu.conso.suivi)+" ")])]),(
          _vm.i18n.navigationMenu.conso.links &&
          _vm.i18n.navigationMenu.conso.links.length > 0
        )?_c('div',{staticClass:"mt-xxs"},_vm._l((_vm.i18n.navigationMenu.conso.links),function(item,index){return _c('dd',{key:index,staticClass:"my-sidenav__item"},[_c('a',{attrs:{"href":item.link.url,"target":item.link.target,"rel":"noopener"}},[_vm._v(" "+_vm._s(item.link.title)+" ")])])}),0):_vm._e()]:_vm._e(),(_vm.i18n.navigationMenu.links && _vm.i18n.navigationMenu.links.length > 0)?_c('div',{staticClass:"mt-m other-links-container"},[_c('ul',_vm._l((_vm.i18n.navigationMenu.links),function(item,index){return _c('li',{key:index,staticClass:"my-sidenav__item"},[_c('a',{attrs:{"href":item.link.url,"target":item.link.target,"rel":"noopener"}},[_vm._v(" "+_vm._s(item.link.title)+" ")])])}),0)]):_vm._e()],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }