










const { NODE_ENV } = process.env
const isDev = NODE_ENV === 'development'

import { defineComponent, onMounted } from '@vue/composition-api'
import { cookiebot } from '@/inc/app.config'
import { useGetters } from '@u3u/vue-hooks'

/* eslint-enable camelcase */

export default defineComponent({
  name: 'cookieTable',
  components: {},
  setup() {
    const cookiebotId = cookiebot.prod
    const { currentLang } = useGetters(['currentLang'])
    // const cookiebotId = isDev ? cookiebot.dev : cookiebot.prod

    onMounted(() => {
      const cookiebotScript = document.createElement('script')
      cookiebotScript.type = 'text/javascript'
      cookiebotScript.src = `https://consent.cookiebot.com/${cookiebotId}/cd.js`
      cookiebotScript.async = true
      cookiebotScript.setAttribute(
        'data-culture',
        currentLang.value.toUpperCase()
      )
      cookiebotScript.id = 'CookieDeclaration'
      document.getElementById('cookieTable')?.appendChild(cookiebotScript)
    })

    return {
      cookiebotId,
    }
  },
})
