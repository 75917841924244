
























































































































import { defineComponent } from '@vue/composition-api'
import CtaRich from '@/components/g/CtaRich.vue'

export default defineComponent({
  name: 'double-cta',
  components: {
    CtaRich,
  },
  props: {
    content: Object,
  },

  setup(props) {
    return {}
  },
})
