
















































































































import Accordion from '@/components/Accordion.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import CtaBanner from '@/components/CtaBanner.vue'
import CtaRich from '@/components/g/CtaRich.vue'
import DualText from '@/components/DualText.vue'
import Header from '@/components/Header.vue'
import RelatedLinks from '@/components/RelatedLinks.vue'
import Steps from '@/components/home/Steps.vue'
import TextColor from '@/components/TextColor.vue'
import TextEnum from '@/components/TextEnum.vue'
import TextOnly from '@/components/TextOnly.vue'
import TextTitle from '@/components/TextTitle.vue'
import TripleCta from '@/components/TripleCta.vue'
import DoubleCta from '@/components/DoubleCta.vue'
import Video from '@/components/g/Video.vue'
import Wizard from '@/components/Wizard.vue'
import Wysiwyg from '@/components/g/Wysiwyg.vue'
import CookieTable from '@/components/CookieTable.vue'

import {
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
} from '@vue/composition-api'
import axios from 'axios'

import { Pages } from '@/inc/types'
import { getApiUrl } from '@/inc/app.config'

/* eslint-disable camelcase */
const subpages: Pages = {
  accordion: Accordion,
  cta_banner: CtaBanner,
  ctaRich: CtaRich,
  homeSteps: Steps,
  related_links: RelatedLinks,
  text_color: TextColor,
  text_enum: TextEnum,
  text_only: TextOnly,
  text_rich: DualText,
  text_shortcodes: Wysiwyg,
  title: TextTitle,
  triple_cta: TripleCta,
  double_cta: DoubleCta,
  v_header: Header,
  v_video: Video,
  wizard: Wizard,
  cookie_table: CookieTable,
}
/* eslint-enable camelcase */

export default defineComponent({
  name: 'undermaintenance',
  components: {
    'v-header': Header,
    Breadcrumb,
    CookieTable,
  },
  setup() {
    const content = ref()
    const isWrapped = cpt => {
      const wrapped = [
        'cta_rich',
        'related_links',
        'v_video',
        'text_shortcodes',
      ]

      return wrapped.some(item => item === cpt)
    }

    onBeforeMount(() => {
      axios.get(`${getApiUrl()}/pages/maintenance`).then(res => {
        content.value = res.data.content
      })
    })

    onMounted(() => {
      function wrap(top, selector, bottom) {
        const matches = document.querySelectorAll('.wysiwyg table')
        for (let i = 0; i < matches.length; i++) {
          const modified = top + matches[i].outerHTML + bottom
          matches[i].outerHTML = modified
        }
      }

      // eslint-disable-next-line quotes
      wrap("<div class='table-wrapper'>", '.getWrapped', '</div>')
    })

    return {
      content,
      isWrapped,
      subpages,
    }
  },
})
