


















































































































































































































































































































































import gtm, { Event } from '@/composables/gtm'

const {
  VUE_APP_ATRIAS_DOMAIN,
  VUE_APP_DOMAIN,
  VUE_APP_MACONSO_DOMAIN,
} = process.env
import { openPopup } from '@/composables/sso'
import { computed, defineComponent } from '@vue/composition-api'
import { useGetters, useState } from '@u3u/vue-hooks'
import CustomLink from '@/components/g/CustomLink.vue'

export default defineComponent({
  name: 'MySidenav',
  components: { CustomLink },
  setup(_props, ctx) {
    const { $router } = ctx.root
    const meterId = computed(() => ctx.root.$route.params.id)
    const { isPpp, getEan } = useGetters('user', ['getEan', 'isPpp'])
    const back = () => {
      $router.go(-1)
    }
    const { user } = useState(['user'])
    const ean = getEan.value(meterId.value)
    const { hasData } = useGetters('my', ['hasData'])
    const { currentLang } = useGetters(['currentLang'])
    const myState = {
      ...useState('my', ['i18n']),
    }

    const clickMyRecord = () => {
      const event = {
        event: 'MyRESA_events',
        eventLabel: 'Acces_suivi_racc',
      } as Event
      gtm.sendEvent(event)
      ctx.root.$router.push({
        name: 'MyRecord',
      })
    }

    const clickUrlPPP = () => {
      const event = {
        event: 'MyRESA_events',
        eventLabel: 'Acces_PPP',
      } as Event
      gtm.sendEvent(event)

      return true
    }

    return {
      i18n: myState.i18n,
      back,
      ean,
      isPpp,
      meterId,
      openPopup,
      VUE_APP_ATRIAS_DOMAIN,
      VUE_APP_DOMAIN,
      VUE_APP_MACONSO_DOMAIN,
      hasData,
      user,
      currentLang,
      clickMyRecord,
      clickUrlPPP,
    }
  },
})
